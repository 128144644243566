<template>
  <form class="form" @submit.stop.prevent="onSubmit">
    <div class="form-group">
      <label for="">Channel Name <span class="text-danger">*</span></label>
      <input
        type="text"
        name=""
        class="form-control"
        id=""
        v-model="channel_name"
        :class="errors['channel_name'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['channel_name']" class="text-danger">{{
        errors["channel_name"][0]
      }}</span>
    </div>
    <div class="form-group">
      <label for="">Host <span class="text-danger">*</span></label>
      <input
        type="text"
        name=""
        class="form-control"
        id=""
        v-model="host"
        :class="errors['host'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['host']" class="text-danger">{{
        errors["host"][0]
      }}</span>
    </div>

    <div class="form-group">
      <label for="">Username<span class="text-danger">*</span></label>
      <input
        type="text"
        name=""
        class="form-control"
        id=""
        v-model="username"
        :class="errors['username'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['username']" class="text-danger">{{
        errors["username"][0]
      }}</span>
    </div>

    <div class="form-group">
      <label for="">Port <span class="text-danger">*</span></label>
      <input
        type="number"
        name=""
        class="form-control"
        id=""
        v-model="port"
        :class="errors['port'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['port']" class="text-danger">{{
        errors["port"][0]
      }}</span>
    </div>

    <div class="form-group">
      <label for="">LSID<span class="text-danger">*</span></label>
      <input
        type="text"
        name=""
        class="form-control"
        id=""
        v-model="lsid"
        :class="errors['lsid'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['lsid']" class="text-danger">{{
        errors["lsid"][0]
      }}</span>
    </div>
    <div class="form-group">
      <label for="">Group File Path<span class="text-danger">*</span></label>
      <input
        type="text"
        name=""
        class="form-control"
        id=""
        v-model="group_out_path"
        :class="errors['group_out_path'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['group_out_path']" class="text-danger">{{
        errors["group_out_path"][0]
      }}</span>
    </div>
    <div class="form-group">
      <label for="">Nist File Path<span class="text-danger">*</span></label>
      <input
        type="text"
        name=""
        class="form-control"
        id=""
        v-model="nist_filepath"
        :class="errors['nist_filepath'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['nist_filepath']" class="text-danger">{{
        errors["nist_filepath"][0]
      }}</span>
    </div>
    <div class="form-group">
      <label for="">Sig File Path<span class="text-danger">*</span></label>
      <input
        type="text"
        name=""
        class="form-control"
        id=""
        v-model="sig_filepath"
        :class="errors['sig_filepath'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['sig_filepath']" class="text-danger">{{
        errors["sig_filepath"][0]
      }}</span>
    </div>

    <hr />
    <!-- 
        <h4 @click="checkTwilioKey()">Checkme</h4> -->
    <button
      class="btn btn-success"
      ref="kt_deporder_config_submit"
      style="float:right"
    >
      Submit
    </button>
  </form>
</template>

<script>
import {
  FETCH_DEPORDER_CONFIG,
  SUBMIT_DEPORDER_CONFIG
} from "@/core/services/store/actions.type";
export default {
  data() {
    return {
      channel_name: "",
      host: "",
      username: "",
      port: "",
      lsid: "",
      nist_filepath: "",
      sig_filepath: "",
      group_out_path: "",
      errors: [],
      id: 0
    };
  },
  created() {
    this.$store.dispatch(FETCH_DEPORDER_CONFIG).then(data => {
      var data_ = data.data;
      this.channel_name = data_.channel_name ? data_.channel_name : "";
      this.host = data_.host ? data_.host : "";
      this.username = data_.username ? data_.username : "";
      this.port = data_.port ? data_.port : "";
      this.lsid = data_.lsid ? data_.lsid : "";
      this.nist_filepath = data_.nist_filepath ? data_.nist_filepath : "";
      this.group_out_path = data_.group_out_path ? data_.group_out_path : "";

      this.sig_filepath = data_.sig_filepath ? data_.sig_filepath : "";
      this.id = data_.id ? data_.id : 0;
    });
  },

  methods: {
    // checkTwilioKey() {
    //   const client = require("twilio")(this.account_sid, this.auth_token);
    //   client.newKeys.create({friendlyName: 'User Joey'})
    //           .then(new_key => console.log(new_key.sid));
    // },
    onSubmit() {
      this.errors = [];
      const submitButton = this.$refs["kt_deporder_config_submit"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      var data = {
        channel_name: this.channel_name,
        host: this.host,
        username: this.username,
        port: this.port,
        lsid: this.lsid,
        nist_filepath: this.nist_filepath,
        group_out_path: this.group_out_path,
        sig_filepath: this.sig_filepath,
        id_: this.id
      };

      this.$store
        .dispatch(SUBMIT_DEPORDER_CONFIG, data)
        .then(data => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.s(data.msg);
        })
        .catch(err => {
          this.errors = err.msg;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          // this.$toastr.e(err.msg);
        });
    }
  }
};
</script>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
