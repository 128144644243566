<template>
  <form class="form" @submit.stop.prevent="onSubmit">
    <h6>Photo Submission</h6>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Username <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            class="form-control"
            id=""
            v-model="username"
            :class="errors['username'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['username']" class="text-danger">{{
            errors["username"][0]
          }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Password <span class="text-danger">*</span></label>
          <div style="position: relative;">
            <input
              :type="password_type"
              v-model="password"
              class="form-control"
              :class="
                errors['password'] && this.password == ''
                  ? 'border border-danger'
                  : ''
              "
            />
            <a @click="showPassword()" style="cursor: pointer;">
              <i
                :class="
                  showPasswordIcon ? 'fa fa-eye text-info' : 'fa fa-eye-slash'
                "
                style="position: absolute;right: 10px;top: 12px;"
              ></i
            ></a>
          </div>
          <span v-if="errors['password']" class="text-danger">{{
            errors["password"][0]
          }}</span>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="">URL <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            class="form-control"
            id=""
            v-model="photo_url"
            :class="errors['photo_url'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['photo_url']" class="text-danger">{{
            errors["photo_url"][0]
          }}</span>
        </div>
      </div>
    </div>

    <hr />
    <h6>Location Submission</h6>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Vendor Name <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            class="form-control"
            id=""
            v-model="sftp_vendor_name"
            :class="errors['sftp_vendor_name'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['sftp_vendor_name']" class="text-danger">{{
            errors["sftp_vendor_name"][0]
          }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Vendor Code <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            class="form-control"
            id=""
            v-model="sftp_vendor_code"
            :class="errors['sftp_vendor_code'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['sftp_vendor_code']" class="text-danger">{{
            errors["sftp_vendor_code"][0]
          }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Username <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            class="form-control"
            id=""
            v-model="sftp_username"
            :class="errors['sftp_username'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['sftp_username']" class="text-danger">{{
            errors["sftp_username"][0]
          }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Password <span class="text-danger">*</span></label>
          <div style="position: relative;">
            <input
              :type="sftp_password_type"
              v-model="sftp_password"
              class="form-control"
              :class="
                errors['sftp_password'] && this.sftp_password == ''
                  ? 'border border-danger'
                  : ''
              "
            />
            <a @click="showSftpPassword()" style="cursor: pointer;">
              <i
                :class="
                  showSftpPasswordIcon
                    ? 'fa fa-eye text-info'
                    : 'fa fa-eye-slash'
                "
                style="position: absolute;right: 10px;top: 12px;"
              ></i
            ></a>
          </div>
          <span v-if="errors['sftp_password']" class="text-danger">{{
            errors["sftp_password"][0]
          }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Host <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            class="form-control"
            id=""
            v-model="sftp_host"
            :class="errors['sftp_host'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['sftp_host']" class="text-danger">{{
            errors["sftp_host"][0]
          }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">Port <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            class="form-control"
            id=""
            v-model="sftp_port"
            :class="errors['sftp_port'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['sftp_port']" class="text-danger">{{
            errors["sftp_port"][0]
          }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="">File Path <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            class="form-control"
            id=""
            v-model="sftp_filepath"
            :class="errors['sftp_filepath'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['sftp_filepath']" class="text-danger">{{
            errors["sftp_filepath"][0]
          }}</span>
        </div>
      </div>
    </div>
    <!-- 
        <h4 @click="checkTwilioKey()">Checkme</h4> -->
    <button
      class="btn btn-success"
      ref="kt_fdle_config_submit"
      style="float:right"
    >
      Submit
    </button>
  </form>
</template>

<script>
import {
  FETCH_ACHA_CONFIG,
  SUBMIT_ACHA_CONFIG
} from "@/core/services/store/actions.type";
export default {
  data() {
    return {
      username: "",
      password: "",
      photo_url: "",
      sftp_vendor_name: "",
      sftp_vendor_code: "",
      sftp_username: "",
      sftp_password: "",
      sftp_host: "",
      sftp_port: "",
      sftp_filepath: "",
      showPasswordIcon: false,
      password_type: "password",
      sftp_password_type: "password",
      showSftpPasswordIcon: false,
      errors: [],
      id: 0
    };
  },
  created() {
    this.$store.dispatch(FETCH_ACHA_CONFIG).then(data => {
      var data_ = data.data;
      this.username = data_.username ? data_.username : "";
      this.password = data_.password ? data_.password : "";
      this.photo_url = data_.photo_url ? data_.photo_url : "";
      this.sftp_vendor_name = data_.sftp_vendor_name
        ? data_.sftp_vendor_name
        : "";
      this.sftp_vendor_code = data_.sftp_vendor_code
        ? data_.sftp_vendor_code
        : "";
      this.sftp_username = data_.sftp_username ? data_.sftp_username : "";
      this.sftp_password = data_.sftp_password ? data_.sftp_password : "";
      this.sftp_host = data_.sftp_host ? data_.sftp_host : "";
      this.sftp_port = data_.sftp_port ? data_.sftp_port : "";
      this.sftp_filepath = data_.sftp_filepath ? data_.sftp_filepath : "";
      this.id = data_.id ? data_.id : 0;
    });
  },

  methods: {
    showPassword() {
      if (!this.showPasswordIcon) {
        this.showPasswordIcon = true;
        this.password_type = "text";
      } else {
        this.showPasswordIcon = false;
        this.password_type = "password";
      }
    },
    showSftpPassword() {
      if (!this.showSftpPasswordIcon) {
        this.showSftpPasswordIcon = true;
        this.sftp_password_type = "text";
      } else {
        this.showSftpPasswordIcon = false;
        this.sftp_password_type = "password";
      }
    },
    // checkTwilioKey() {
    //   const client = require("twilio")(this.account_sid, this.auth_token);
    //   client.newKeys.create({friendlyName: 'User Joey'})
    //           .then(new_key => console.log(new_key.sid));
    // },
    onSubmit() {
      this.errors = [];
      const submitButton = this.$refs["kt_fdle_config_submit"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      var data = {
        username: this.username,
        password: this.password,
        sftp_vendor_name: this.sftp_vendor_name,
        sftp_vendor_code: this.sftp_vendor_code,
        sftp_username: this.sftp_username,
        sftp_password: this.sftp_password,
        sftp_host: this.sftp_host,
        sftp_port: this.sftp_port,
        photo_url: this.photo_url,
        sftp_filepath: this.sftp_filepath,
        id_: this.id
      };

      this.$store
        .dispatch(SUBMIT_ACHA_CONFIG, data)
        .then(data => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.s(data.msg);
        })
        .catch(err => {
          this.errors = err.msg;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          // this.$toastr.e(err.msg);
        });
    }
  }
};
</script>
